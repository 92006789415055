import { css } from '@emotion/react';
import { font, grid } from '@prototyp/gatsby-plugin-gumball/utils';
import { breakpoints, colors, fontFamily, spacing } from 'style';

const root = css`
  width: 100%;
  padding-bottom: calc(var(--unit) * 6);
`;

const rootDark = css`
  ${root}
  background-color: ${colors.text};
  color: ${colors.white};
  padding-top: calc(var(--unit) * 20);
  padding-bottom: calc(var(--unit) * 20);
`;

const wrapper = css`
  max-width: 1304px;
  width: 100%;
  margin: 0 auto;
  padding: calc(var(--unit) * 6) calc(var(--unit) * 5);
  padding-bottom: calc(var(--unit) * 6);
  font-family: ${fontFamily.secondary};
`;

const contentWrapper = (colStart: number, colSpan: number) => css`
  @media ${breakpoints.medium} {
    grid-column: ${colStart} / span ${colSpan};
  }
`;

const label = (colStart: number, colSpan: number) => css`
  ${font.size.tiny}
  letter-spacing: var(--unit);
  text-transform: uppercase;
  font-weight: var(--font-weight-regular);
  ${contentWrapper(colStart, colSpan)}
`;

const labelGreen = css`
  ${font.size.tiny}
  letter-spacing: var(--unit);
  font-weight: var(--font-weight-regular);
  text-transform: uppercase;
  color: hsl(var(--color-secondary-6));
  margin-top: calc(var(--unit) * 8);

  @media ${breakpoints.medium} {
    margin-top: calc(var(--unit) * 12);
  }
`;

const title = css`
  font-weight: 500;
  font-size: calc(var(--unit) * 6);
  line-height: 120%;
  margin-top: calc(var(--unit) * 6);

  ${contentWrapper(1, 4)}
`;

const textPrimary = css`
  font-size: calc(var(--unit) * 4);
  line-height: 150%;
  margin-top: ${spacing.med};
`;

const link = css`
  position: relative;
  color: ${colors.white};
  transition: all 0.3s ease-in-out;

  &:before {
    content: '';
    position: absolute;
    width: 0;
    height: 1px;
    bottom: -4px;
    left: 0;
    background-color: ${colors.secondary};
    visibility: hidden;
    transition: all 0.3s ease-in-out;
  }

  &:hover {
    color: ${colors.secondary};
  }

  &:hover:before {
    visibility: visible;
    width: 100%;
  }
`;

const linkGreen = css`
  ${link}
  top: calc(var(--unit) * 10);
  color: ${colors.primary};
  width: fit-content;
`;

const imagePrimary = css`
  width: calc(var(--unit) * 13);
  height: ${spacing.xxxlrg};
  margin-right: ${spacing.xxlrg};
  margin-bottom: ${spacing.xxlrg};
  margin-top: ${spacing.lrg};
`;

const imageSecondary = css`
  ${imagePrimary}
  width: calc(var(--unit) * 30);
`;

const divider = css`
  width: 100%;
  opacity: 0.1;
  ${grid.colSpan.c12}
  margin-top: var(--spacing-huge);

  @media ${breakpoints.large} {
    margin-top: calc(var(--unit) * 12);
  }
`;

const marginTopXHuge = css`
  margin-top: 66px;
`;

const marginTopMed = css`
  margin-top: var(--spacing-med);
`;

const textSecondary = css`
  ${textPrimary}
  ${marginTopXHuge}
`;

const img = css`
  ${grid.colSpan.c6}
  ${grid.colStart.c1}
  margin-bottom: calc(var(--unit) * 9);
`;

export const footerStyles = {
  root,
  rootDark,
  wrapper,
  contentWrapper,
  label,
  labelGreen,
  title,
  textPrimary,
  link,
  linkGreen,
  imagePrimary,
  imageSecondary,
  divider,
  textSecondary,
  img,
  marginTopXHuge,
  marginTopMed,
};
