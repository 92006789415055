import { css } from '@emotion/react';
import { colors } from 'style';

const text = css`
  font-family: var(--font-family-secondary);
  font-size: var(--font-size-small);
  line-height: var(--line-height-small);
  font-weight: var(--font-weight-regular);
`;

const container = css`
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;

  padding: var(--spacing-med) var(--spacing-xlrg);
`;

const containerTextPrimary = css`
  ${text}
  color: ${colors.white};
`;

const containerTextSecondary = css`
  ${text}
  color: ${colors.black};
`;

const linePrimary = css`
  width: calc(var(--unit) - 2px);
  height: calc(var(--unit) * 3);
  background-color: currentColor;
  margin-left: var(--spacing-med);
  border-radius: calc(var(--unit) * 2 - 2px);
`;

const lineSecondary = css`
  ${linePrimary}
  margin-left: calc(var(--spacing-tny) + 1.5px);
`;

const dropdownItem = css`
  width: calc(var(--unit) * 26);
  padding: var(--spacing-lrg);

  color: ${colors.black};
  background-color: ${colors.white};
  transition: all 0.3s ease-in-out;

  &:hover {
    background-color: ${colors.primary};
    color: ${colors.white};
  }

  &:nth-of-type(1) {
    border-color: ${colors.borderLight};
    border-style: solid solid none;
    border-width: 1px;
    border-radius: calc(var(--unit) * 2 - 2px) calc(var(--unit) * 2 - 2px) 0 0;
  }

  &:nth-of-type(2) {
    border-color: ${colors.borderLight};
    border-style: none solid;
    border-width: 1px;
  }

  &:nth-of-type(3) {
    border-color: ${colors.borderLight};
    border-style: none solid solid;
    border-width: 1px;
    border-radius: 0 0 calc(var(--unit) * 2 - 2px) calc(var(--unit) * 2 - 2px);
  }
`;

const dropdown = css`
  position: absolute;
  display: block;
  left: var(--spacing-xlrg);
  bottom: calc(0px - var(--unit) * 38);

  z-index: 6;
`;

const dropdownActive = css`
  ${dropdown}
  visibility: visible;
`;

const dropdownClosed = css`
  ${dropdown}
  visibility: hidden;
`;

export const switcherStyles = {
  container,
  containerTextPrimary,
  containerTextSecondary,
  dropdownItem,
  linePrimary,
  lineSecondary,
  dropdown,
  dropdownActive,
  dropdownClosed,
};
