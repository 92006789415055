import { Footer } from 'components/footer';
import { Navigation } from 'components/navigation';
import React from 'react';

interface Props {
  isGhostNav: boolean;
  isLoading?: boolean;
  error?: unknown;
}

export const Layout: React.FC<Props> = ({ isGhostNav, children }) => {
  return (
    <>
      <Navigation isNavGhost={isGhostNav} />
      <section>{children}</section>
      <Footer />
    </>
  );
};
