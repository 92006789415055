import { changeLocale, useIntl } from 'gatsby-plugin-react-intl';
import React from 'react';
import { switcherStylesMobile } from './LanguageSwitcherMobile.styles';

export const LanguageSwitcherMobile: React.FC = () => {
  const { locale } = useIntl();
  const languages = ['hr', 'en', 'de', 'sr'];
  function handleLanguageChange(event: React.MouseEvent<HTMLButtonElement>) {
    const { value } = event.currentTarget;
    if (!value) {
      return;
    }

    changeLocale(value);
  }

  return (
    <div css={switcherStylesMobile.container}>
      {languages.map((lang, i) => (
        <div css={switcherStylesMobile.container} key={lang}>
          <button
            key={lang}
            value={lang}
            onClick={handleLanguageChange}
            css={[
              switcherStylesMobile.button,
              locale === lang && switcherStylesMobile.buttonActive,
            ]}
          >
            {lang.toUpperCase()}
          </button>
          {i != 3 && <div css={switcherStylesMobile.divider}></div>}
        </div>
      ))}
    </div>
  );
};
