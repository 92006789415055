import { flex } from '@prototyp/gatsby-plugin-gumball/utils';
import { AnimatePresence, motion } from 'framer-motion';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { FormattedMessage, Link, useIntl } from 'gatsby-plugin-react-intl';
import Hamburger from 'hamburger-react';
import { useImages } from 'hooks';
import { LanguageSwitcher, LanguageSwitcherMobile } from 'modules/i18n';
import React, { useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { nav } from './Navigation.styles';

interface Props {
  isNavGhost: boolean;
}

export const Navigation: React.FC<Props> = ({ isNavGhost }) => {
  const { ref } = useInView();
  const { formatMessage } = useIntl();
  const { navImage, logoGhost, logoGhostNav, logo } = useImages();
  const [isNavOpen, setIsNavOpen] = useState<boolean>(false);
  const logoGhostData = getImage(logoGhost);
  const logoGhostNavData = getImage(logoGhostNav);
  const logoData = getImage(logo);
  const navImageData = getImage(navImage);
  const hamburgerColor = isNavOpen ? '#FFFFFF' : '#242424';

  function closeMenu() {
    setIsNavOpen(false);
  }

  useEffect(() => {
    document.body.style.overflow = isNavOpen ? 'hidden' : 'visible';
    window.scrollTo(0, 0);
  }, [isNavOpen]);

  return (
    <nav css={nav.root}>
      <div css={nav.navButton}>
        <Hamburger
          toggled={isNavOpen}
          toggle={() => setIsNavOpen(!isNavOpen)}
          size={20}
          color={isNavGhost ? '#FFFFFF' : hamburgerColor}
          hideOutline={true}
        />
      </div>
      <div css={nav.wrapper}>
        {!isNavOpen && (
          <div css={nav.navWrapper}>
            <Link to={`/`}>
              <div css={nav.logoWrapper}>
                {logoGhostData && logoData && (
                  <GatsbyImage
                    loading="lazy"
                    image={isNavGhost ? logoGhostData : logoData}
                    alt={formatMessage({ id: 'images.titles.logo' })}
                  />
                )}
              </div>
            </Link>
            <section css={[nav.contentWrapper, nav.active]}>
              <Link
                to={`/destinations/`}
                css={nav.link(isNavGhost)}
                activeClassName="nav-active--primary"
                partiallyActive={true}
              >
                <FormattedMessage id="navigation.destinations" />
              </Link>
              <Link
                to={`/program/`}
                css={nav.link(isNavGhost)}
                activeClassName="nav-active--primary"
                partiallyActive={true}
              >
                <FormattedMessage id="navigation.packages" />
              </Link>
              <Link
                to={`/blog/`}
                css={nav.link(isNavGhost)}
                activeClassName="nav-active--primary"
                partiallyActive={true}
              >
                <FormattedMessage id="navigation.blog" />
              </Link>
              <div css={nav.switcherContainerPrimary}>
                <LanguageSwitcher isNavGhost={isNavGhost} />
              </div>
            </section>
          </div>
        )}
        <AnimatePresence>
          {isNavOpen && (
            <motion.section
              ref={ref}
              initial={{ height: '100vh', opacity: 0 }}
              animate={{ height: '100vh', opacity: 0.97 }}
              exit={{ height: '100vh', opacity: 0 }}
              transition={{ ease: 'easeInOut', duration: 0.6 }}
              css={nav.menuWrapper}
            >
              <motion.div
                initial={{ height: '100vh', opacity: 0 }}
                animate={{ height: '100vh', opacity: 0.97 }}
                exit={{ height: '100vh', opacity: 0 }}
                transition={{ ease: 'easeInOut', duration: 0.6 }}
                css={nav.imgWrapper}
              >
                {navImageData && (
                  <GatsbyImage
                    loading="lazy"
                    image={navImageData}
                    alt={formatMessage({
                      id: 'images.titles.cereal-field',
                    })}
                    css={nav.navImg}
                  />
                )}
                <p css={nav.copyrightPrimary}>© Copyright Panona Tours 2021</p>
              </motion.div>
              <div css={nav.mobileWrapper}>
                <motion.div
                  css={[flex.display.flex, flex.justify.between]}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  transition={{ ease: 'easeInOut', duration: 0.6 }}
                >
                  <Link to={`/`} onClick={closeMenu}>
                    <div css={nav.logoWrapper}>
                      {logoGhostNavData && (
                        <GatsbyImage
                          loading="lazy"
                          image={logoGhostNavData}
                          alt={formatMessage({ id: 'images.titles.logo' })}
                        />
                      )}
                    </div>
                  </Link>
                </motion.div>
                <hr css={nav.divider} />
                <div css={[nav.menuLinkWrapper, nav.active]}>
                  <motion.div
                    initial={{ opacity: 0, y: '-40px' }}
                    animate={{ opacity: 1, y: '0' }}
                    exit={{ opacity: 0, y: '-40px' }}
                    transition={{ ease: 'easeInOut', duration: 0.6 }}
                  >
                    <Link
                      to={`/destinations/`}
                      onClick={closeMenu}
                      css={nav.menuLinkMobile}
                      activeClassName="nav-active--secondary"
                      partiallyActive={true}
                    >
                      <FormattedMessage id="navigation.destinations" />
                    </Link>
                  </motion.div>
                  <motion.div
                    initial={{ opacity: 0, y: '-80px' }}
                    animate={{ opacity: 1, y: '0' }}
                    exit={{ opacity: 0, y: '-80px' }}
                    transition={{ ease: 'easeInOut', duration: 0.52 }}
                  >
                    <Link
                      to={`/program/`}
                      onClick={closeMenu}
                      css={nav.menuLinkMobile}
                      activeClassName="nav-active--secondary"
                      partiallyActive={true}
                    >
                      <FormattedMessage id="navigation.packages" />
                    </Link>
                  </motion.div>
                  <motion.div
                    initial={{ opacity: 0, y: '-120px' }}
                    animate={{ opacity: 1, y: '0' }}
                    exit={{ opacity: 0, y: '-120px' }}
                    transition={{ ease: 'easeInOut', duration: 0.54 }}
                  >
                    <Link
                      to={`/about/`}
                      onClick={closeMenu}
                      css={nav.menuLink}
                      activeClassName="nav-active--secondary"
                      partiallyActive={true}
                    >
                      <FormattedMessage id="navigation.about-us" />
                    </Link>
                  </motion.div>
                  <motion.div
                    initial={{ opacity: 0, y: '-160px' }}
                    animate={{ opacity: 1, y: '0' }}
                    exit={{ opacity: 0, y: '-160px' }}
                    transition={{ ease: 'easeInOut', duration: 0.56 }}
                  >
                    <Link
                      to={`/about-project/`}
                      onClick={closeMenu}
                      css={nav.menuLink}
                      activeClassName="nav-active--secondary"
                      partiallyActive={true}
                    >
                      <FormattedMessage id="navigation.about-project" />
                    </Link>
                  </motion.div>
                  <motion.div
                    initial={{ opacity: 0, y: '-200px' }}
                    animate={{ opacity: 1, y: '0' }}
                    exit={{ opacity: 0, y: '-200px' }}
                    transition={{ ease: 'easeInOut', duration: 0.58 }}
                  >
                    <Link
                      to={`/blog/`}
                      onClick={closeMenu}
                      css={nav.menuLinkMobile}
                      activeClassName="nav-active--secondary"
                      partiallyActive={true}
                    >
                      <FormattedMessage id="navigation.blog" />
                    </Link>
                  </motion.div>
                  <motion.div
                    initial={{ opacity: 0, y: '-240px' }}
                    animate={{ opacity: 1, y: '0' }}
                    exit={{ opacity: 0, y: '-240px' }}
                    transition={{ ease: 'easeInOut', duration: 0.6 }}
                  >
                    <Link
                      to={`/contact/`}
                      onClick={closeMenu}
                      css={nav.menuLink}
                      activeClassName="nav-active--secondary"
                      partiallyActive={true}
                    >
                      <FormattedMessage id="navigation.contact" />
                    </Link>
                  </motion.div>
                  <motion.div
                    initial={{ opacity: 0, y: '-280px' }}
                    animate={{ opacity: 1, y: '0' }}
                    exit={{ opacity: 0, y: '-280px' }}
                    transition={{ ease: 'easeInOut', duration: 0.62 }}
                  >
                    <a
                      href="https://forum.panonatours.com/"
                      target="_blank"
                      onClick={closeMenu}
                      css={nav.menuLink}
                      rel="noreferrer noopener"
                    >
                      <FormattedMessage id="navigation.forum" />
                    </a>
                  </motion.div>
                  <motion.div
                    initial={{ opacity: 0, y: '-320px' }}
                    animate={{ opacity: 1, y: '0' }}
                    exit={{ opacity: 0, y: '-320px' }}
                    transition={{ ease: 'easeInOut', duration: 0.64 }}
                    css={nav.copyrightWrapper}
                  >
                    <div css={nav.switcherContainerSecondary}>
                      <LanguageSwitcherMobile />
                    </div>
                    <p css={nav.copyrightSecondary}>
                      © Copyright Panona Tours 2021
                    </p>
                  </motion.div>
                </div>
              </div>
            </motion.section>
          )}
        </AnimatePresence>
      </div>
    </nav>
  );
};
