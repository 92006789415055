import OpcinaVelika from 'assets/icons/opcinaVelikaLogo.svg';
import SlapLogo from 'assets/icons/slapLogo.svg';
import SuboticaFond from 'assets/icons/suboticaFondLogo.svg';
import UniversitySubotica from 'assets/icons/universitySubotica.svg';
import VukovarLogo from 'assets/icons/vukovarLogo.svg';
import CroSvg from 'assets/icons/RH.svg';
import React from 'react';
import { footerStyles } from './Footer.styels';
import { font, margin } from '@prototyp/gatsby-plugin-gumball/utils';
import { Grid } from 'components';
import { useImages } from 'hooks';
import { getSrc } from 'gatsby-plugin-image';
import { FormattedMessage } from 'gatsby-plugin-react-intl';

export const Footer: React.FC = () => {
  const { interregLogo } = useImages();

  return (
    <>
      <section css={footerStyles.rootDark}>
        <Grid type="page" css={font.family.secondary}>
          <h4 css={footerStyles.label(1, 5)}>
            <FormattedMessage id="footer.subtitle" />
          </h4>
          <h3 css={footerStyles.title}>
            <FormattedMessage id="footer.title" />
          </h3>
          <div css={footerStyles.contentWrapper(1, 4)}>
            <h4 css={footerStyles.labelGreen}>
              <FormattedMessage id="footer.info.title" />
            </h4>
            <p css={footerStyles.textPrimary}>
              <FormattedMessage id="footer.info.adress" />
            </p>
            <p css={footerStyles.textPrimary}>
              <FormattedMessage id="footer.info.phone" />:{' '}
              <a href="tel:031-213-556" css={footerStyles.link}>
                +385 31 213 556
              </a>
            </p>
            <p css={footerStyles.textPrimary}>
              <FormattedMessage id="footer.info.email" />:{' '}
              <a href="mailto:udruga.slap@gmail.com" css={footerStyles.link}>
                udruga.slap@gmail.com
              </a>
            </p>
          </div>
          <div css={footerStyles.contentWrapper(5, 4)}>
            <h4 css={footerStyles.labelGreen}>
              <FormattedMessage id="footer.about-project.title" />
            </h4>
            <p css={footerStyles.textPrimary}>
              <FormattedMessage id="footer.about-project.paragraph-project" />
            </p>
            <p css={footerStyles.textPrimary}>
              <FormattedMessage id="footer.about-project.paragraph-partner" />
            </p>
            <p css={footerStyles.textPrimary}>
              <FormattedMessage id="footer.about-project.paragraph-duration" />
            </p>
            <p css={footerStyles.textPrimary}>
              <FormattedMessage id="footer.about-project.paragraph-budget" />
            </p>
            <p css={footerStyles.textPrimary}>
              <FormattedMessage id="footer.about-project.paragraph-contribution" />
            </p>
          </div>
          <div css={footerStyles.contentWrapper(9, 4)}>
            <h4 css={footerStyles.labelGreen}>
              <FormattedMessage id="footer.partners.title" />
            </h4>
            <p css={footerStyles.textPrimary}>
              <FormattedMessage id="footer.partners.city" />
            </p>
            <p css={footerStyles.textPrimary}>
              <FormattedMessage id="footer.partners.university" />
            </p>
            <p css={footerStyles.textPrimary}>
              <FormattedMessage id="footer.partners.photo" />
            </p>
            <div>
              <SlapLogo css={footerStyles.imagePrimary} />
              <VukovarLogo css={footerStyles.imagePrimary} />
              <OpcinaVelika css={footerStyles.imagePrimary} />
            </div>
            <UniversitySubotica css={footerStyles.imageSecondary} />
            <SuboticaFond />
          </div>

          <hr css={footerStyles.divider} />

          <div css={footerStyles.contentWrapper(1, 4)}>
            <h4 css={footerStyles.labelGreen}>
              <FormattedMessage id="footer.co-financing.title" />
            </h4>
            <p css={footerStyles.textPrimary}>
              <FormattedMessage id="footer.co-financing.paragraph-one" />
            </p>
          </div>

          <div
            css={[
              footerStyles.contentWrapper(5, 4),
              footerStyles.marginTopXHuge,
            ]}
          >
            <p css={footerStyles.textPrimary}>
              <FormattedMessage id="footer.co-financing.paragraph-two" />
            </p>
          </div>

          <div
            css={[
              footerStyles.contentWrapper(9, 4),
              footerStyles.marginTopXHuge,
            ]}
          >
            <CroSvg css={footerStyles.marginTopMed} />
          </div>
        </Grid>
      </section>
      <section css={footerStyles.root}>
        <Grid type="page" css={[footerStyles.wrapper]}>
          <img
            loading="lazy"
            src={getSrc(interregLogo)}
            css={footerStyles.img}
            alt="Interreg logo"
          />
          <p
            css={[footerStyles.contentWrapper(1, 5), footerStyles.textPrimary]}
          >
            <FormattedMessage id="footer.co-financing.paragraph-three" />
          </p>
          <a
            href="https://www.interreg-croatia-serbia2014-2020.eu/"
            css={[
              footerStyles.contentWrapper(1, 12),
              footerStyles.linkGreen,
              margin.bottom.xxlrg,
            ]}
          >
            www.interreg-croatia-serbia2014-2020.eu
          </a>
        </Grid>
      </section>
    </>
  );
};
