import { css } from '@emotion/react';
import { colors } from 'style';

const container = css`
  display: flex;
  align-items: center;
`;

const button = css`
  position: relative;
  font-family: var(--font-family-secondary);
  font-size: var(--font-size-small);
  font-weight: var(--font-weight-regular);
  line-height: var(--line-height-small);
  padding: var(--spacing-sml);

  cursor: pointer;
  background-color: transparent;
  color: ${colors.white};
  border: none;

  &:before {
    content: '';
    position: absolute;
    width: 0;
    height: 1px;
    bottom: 1px;
    background-color: ${colors.white};
    visibility: hidden;
    transition: all 0.3s ease-in-out;
  }

  &:hover:before {
    visibility: visible;
    width: calc(100% - var(--unit) * 4);
  }
`;

const buttonActive = css`
  position: relative;
  font-weight: var(--font-weight-bold);
  color: ${colors.white};

  &:after {
    content: '';
    position: absolute;
    width: calc(100% - var(--unit) * 4);
    height: 1px;
    bottom: 1px;
    left: var(--spacing-sml);
    background-color: ${colors.white};
  }
`;

const divider = css`
  width: calc(var(--unit) * 5);
  height: 1px;
  background-color: ${colors.white};
`;

const dividerGhost = css`
  background-color: ${colors.white};
`;

export const switcherStylesMobile = {
  container,
  button,
  divider,
  dividerGhost,
  buttonActive,
};
