import { changeLocale, useIntl } from 'gatsby-plugin-react-intl';
import React, { useState } from 'react';
import { switcherStyles } from './LanguageSwitcher.styles';
import { motion } from 'framer-motion';

interface Props {
  isNavGhost: boolean;
}

export const LanguageSwitcher: React.FC<Props> = ({ isNavGhost }) => {
  const { locale } = useIntl();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const languages = ['hr', 'en', 'de', 'sr'];

  return (
    <div
      css={[
        switcherStyles.container,
        isNavGhost
          ? switcherStyles.containerTextPrimary
          : switcherStyles.containerTextSecondary,
      ]}
      onClick={() => setIsDropdownOpen(!isDropdownOpen)}
    >
      {locale.toUpperCase()}
      <motion.div
        initial={{ rotate: -45, x: 0 }}
        animate={{ x: isDropdownOpen ? '3.7px' : 0 }}
        transition={{
          ease: 'easeInOut',
          duration: 0.3,
          delay: 0.1,
        }}
        css={switcherStyles.linePrimary}
      ></motion.div>
      <motion.div
        initial={{ rotate: 45, x: 0 }}
        animate={{ x: isDropdownOpen ? '-3.5px' : 0 }}
        transition={{
          ease: 'linear',
          duration: 0.3,
          delay: 0.1,
        }}
        css={switcherStyles.lineSecondary}
      ></motion.div>
      <motion.div
        initial={{
          opacity: 0,
          y: '-40px',
        }}
        animate={{
          opacity: isDropdownOpen ? 1 : 0,
          y: isDropdownOpen ? 0 : '-40px',
        }}
        transition={{
          ease: 'easeInOut',
          duration: 0.3,
          delay: 0.1,
        }}
        css={[
          !isDropdownOpen
            ? switcherStyles.dropdownClosed
            : switcherStyles.dropdownActive,
        ]}
      >
        {languages
          .filter((lang) => lang !== locale)
          .map((lang) => (
            <motion.div
              key={lang}
              onClick={() => changeLocale(lang)}
              css={switcherStyles.dropdownItem}
            >
              {lang.toUpperCase()}
            </motion.div>
          ))}
      </motion.div>
    </div>
  );
};
